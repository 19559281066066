import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import SuccessC from '../../../components/success/SuccessC';

function SuccessTemplate({ cmsData }) {
    
    const data = cmsData?.success?.edges[0]?.node?.frontmatter ?? {}

    return (
        <div>
            <SuccessC cmsData={data} />
        </div>
    )
}

export default function Success() {
    return (
      <StaticQuery
        query={graphql`
          query {
            success: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "sv-success"}}}) {
              edges {
                node {
                  frontmatter {
                    seo {
                        title
                        descr
                    }
                    bgImage {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    personImage {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    title
                    lines {
                        text
                    }
                    personName
                    role
                  }
                }
              }
            },
          }
        `}
        render={(data) => <SuccessTemplate cmsData={data} />}
      />
    );
  }
